<template>
  <ValidationObserver
    ref="createGradeModal"
  >
    <b-modal
      id="create-grade-modal"
      ref="create-grade-modal"
      :title="title"
      centered
      no-close-on-backdrop
      :ok-title="buttonTitle"
      :ok-disabled="isLoading"
      cancel-variant="outline-secondary"
      size="sm"
      class="p-0"
      @show="setModalData"
      @ok="handleForm"
      @hidden="resetModal"
    >
      <b-overlay
        id="overlay-background"
        :show="isLoading"
        variant="white"
        opacity="1"
        rounded="sm"
      >
        <b-form-group
          label="Grade Name"
        >
          <ValidationProvider
            #default="{ errors }"
            name="Name"
            rules="required"
          >
            <b-form-input
              v-model="name"
              placeholder="Name"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>
      </b-overlay>
    </b-modal>
  </ValidationObserver>
</template>

<script>
import {
  BModal,
  BOverlay,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import useClassesList from '@/views/admin/academics/classes-list/useClassesList'

export default {
  name: 'CreateGradeModal',
  components: {
    BModal,
    BOverlay,
    BFormGroup,
    BFormInput,

    ValidationProvider,
    ValidationObserver,
  },
  props: {
    mode: {
      type: String,
      required: true,
      default: 'create',
    },
    selectedGrade: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      name: '',
      required,
    }
  },
  computed: {
    programId() {
      return this.$store.getters['verticalMenu/getDefaultProgram']
    },
    title() {
      return this.mode === 'create' ? 'Create Grade' : 'Edit Grade'
    },
    buttonTitle() {
      return this.mode === 'create' ? 'Create' : 'Update'
    },
  },
  setup() {
    const {
      createGrade,
      updateGrade,
    } = useClassesList()

    return {
      createGrade,
      updateGrade,
    }
  },
  methods: {
    handleForm(bvModalEvt) {
      bvModalEvt.preventDefault()

      return new Promise((resolve, reject) => {
        this.$refs.createGradeModal.validate().then(success => {
          if (success) {
            resolve(true)
            if (this.mode === 'create') {
              this.handleCreateGrade()
            } else {
              this.handleUpdateGrade()
            }
          } else {
            reject()
          }
        })
      })
    },
    async handleCreateGrade() {
      this.isLoading = true
      const queryParams = {
        program_id: this.programId,
        name: this.name,
        type: 'Grade',
      }

      await this.createGrade(queryParams)
        .then(() => {
          this.$emit('success')
        })
        .finally(() => {
          this.isLoading = false
          this.$bvModal.hide('create-grade-modal')
        })
    },
    async handleUpdateGrade() {
      this.isLoading = true
      const queryParams = {
        id: this.selectedGrade.id,
        program_id: this.programId,
        name: this.name,
      }

      await this.updateGrade(queryParams)
        .then(() => {
          this.$emit('success')
        })
        .finally(() => {
          this.isLoading = false
          this.$bvModal.hide('create-grade-modal')
        })
    },
    setModalData() {
      this.name = this.mode === 'create' ? '' : this.selectedGrade.name
    },
    resetModal() {
      this.name = ''
    },
  },
}
</script>

<style>

</style>
