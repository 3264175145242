import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useClassesList() {
  // Use toast
  const toast = useToast()

  const fetchGrades = queryParam => axios
    .get('auth/academics/list', {
      params: queryParam,
    })
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error fetching grades',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const fetchSubjects = queryParam => axios
    .get('/auth/academics/subjects', {
      params: queryParam,
    })
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error fetching subjects',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const fetchTeachersList = queryParam => axios
    .get('/auth/staffs', {
      params: queryParam,
    })
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error fetching staff list',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const fetchStudentsForSelectList = queryParam => axios
    .get('/auth/campers-for-select', {
      params: queryParam,
    })
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error fetching staff list',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const addStudentsToClass = queryParams => axios
    .post(`/auth/groups/${queryParams.group_id}/add-users`, queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Students was successfully added to the class',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data
    })
    .catch(error => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error adding students',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return error.response
    })

  const createGrade = queryParams => axios
    .post('/auth/academics/create', queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Grade was successfully created',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error creating new grade',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const updateGrade = queryParams => axios
    .put(`/auth/academics/update/${queryParams.id}`, queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Grade was successfully updated',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error updating grade',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const deleteGrade = ID => axios
    .delete(`auth/groups/${ID}`)
    .then(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Success deleting grade',
          icon: 'Trash2Icon',
          variant: 'info',
        },
      })
    })
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error deleting grade',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })
  const createSubject = queryParams => axios
    .post('/auth/academics/subjects', queryParams)
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error creating new Subject',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const addSubjectToClass = queryParams => axios
    .post(`/auth/academics/update-subject-relation/${queryParams.group_id}`, queryParams)
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error creating new Subject',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const assignTeacher = queryParams => axios
    .put(`/auth/academics/subjects/${queryParams.id}`, queryParams)
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error assigning teacher',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const addClass = queryParams => axios
    .post('/auth/academics/create', queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Class was successfully added',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error adding new class',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchGrades,
    fetchSubjects,
    fetchTeachersList,
    fetchStudentsForSelectList,
    addStudentsToClass,
    createGrade,
    updateGrade,
    deleteGrade,
    createSubject,
    addSubjectToClass,
    assignTeacher,
    addClass,
  }
}
