<template>
  <div>
    <b-overlay
      id="overlay-background"
      :show="isLoading"
      variant="white"
      opacity="1"
      rounded="sm"
    >
      <b-card
        no-body
      >
        <!-- Header-->
        <b-card-header>
          <b-col
            md="6"
          >
            <h3>Classes</h3>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <label class="mr-1 mb-0">Search</label>
              <b-form-input
                v-model="searchQuery"
                placeholder="Search"
                class="w-25"
                debounce="350"
              />

              <b-button
                v-if="$can('create-grade', permissionSubjects.Academics)"
                variant="primary"
                class="ml-2"
                @click="showCreateGradeModal"
              >
                <span class="align-middle">Add Grade</span>
              </b-button>

            </div>
          </b-col>
        </b-card-header>

        <div v-if="gradesList.length">
          <div class="grades-list">
            <grade-card
              v-for="grade in gradesList"
              :key="grade.id"
              :grade="grade"
              @showAddClassToGrade="showAddClassToGradeModal"
              @showEditGradeModal="showEditGradeModal"
              @showDeleteGradeModal="showDeleteGradeModal"
            />
          </div>
        </div>
        <b-card v-else>
          <div class="text-center">
            No matching records found
          </div>
        </b-card>
      </b-card>
    </b-overlay>

    <create-grade-modal
      :mode="gradeModalMode"
      :selected-grade="selectedGrade"
      @success="fetchGradesList"
    />

    <add-class-modal
      v-if="selectedGrade"
      :selected-grade-id="selectedGrade.id"
      :grades-list="gradesList"
      @success="fetchGradesList"
    />

    <confirmation-modal
      v-if="deletableGrade"
      :toggle-modal="confirmationModalShow"
      :title="'Are you sure'"
      :message="`Are you sure you want to permanently delete this grade(${deletableGrade.name})?`"
      :is-loading="isGradeDeleteLoading"
      @close-modal="confirmationModalShow = !confirmationModalShow"
      @confirm="handleDeleteGrade"
    />
  </div>
</template>

<script>
import {
  BOverlay,
  BCard,
  BCardHeader,
  BCol,
  BFormInput,
  BButton,
} from 'bootstrap-vue'

import GradeCard from './grade-card/GradeCard.vue'
import CreateGradeModal from './modals/CreateGradeModal.vue'
import AddClassModal from './modals/AddClassModal.vue'
import useClassesList from '@/views/admin/academics/classes-list/useClassesList'
import ConfirmationModal from '@/views/components/confirmation/ConfirmationModal.vue'
import { permissionSubjects } from '@/libs/acl/constants'

export default {
  name: 'ClassesList',
  components: {
    BOverlay,
    BCard,
    BCardHeader,
    BCol,
    BFormInput,
    BButton,

    GradeCard,
    CreateGradeModal,
    AddClassModal,
    ConfirmationModal,
  },
  data() {
    return {
      isLoading: false,
      searchQuery: '',
      gradesList: [],
      groupTypeOptions: [],
      selectedGrade: null,
      deletableGrade: null,
      gradeModalMode: 'create',
      confirmationModalShow: false,
      isGradeDeleteLoading: false,
      permissionSubjects,
    }
  },
  setup() {
    const {
      fetchGrades,
      deleteGrade,
    } = useClassesList()

    return {
      fetchGrades,
      deleteGrade,
    }
  },
  computed: {
    programId() {
      return this.$store.getters['verticalMenu/getDefaultProgram']
    },
  },
  watch: {
    searchQuery() {
      this.fetchGradesList()
    },
    programId() {
      this.fetchGradesList()
    },
  },
  async created() {
    await this.fetchGradesList()
  },
  methods: {
    async fetchGradesList() {
      try {
        this.isLoading = true
        const queryParams = {
          program_id: this.programId,
          sortBy: 'name',
        }

        if (this.searchQuery) queryParams.name = this.searchQuery

        this.gradesList = await this.fetchGrades(queryParams)
      } finally {
        this.isLoading = false
      }
    },
    showAddClassToGradeModal(grade) {
      this.selectedGrade = grade
      this.$nextTick(() => {
        this.$bvModal.show('add-class-modal')
      })
    },
    showCreateGradeModal() {
      this.selectedGrade = null
      this.gradeModalMode = 'create'
      this.$nextTick(() => {
        this.$bvModal.show('create-grade-modal')
      })
    },
    showEditGradeModal(grade) {
      this.selectedGrade = grade
      this.gradeModalMode = 'edit'
      this.$nextTick(() => {
        this.$bvModal.show('create-grade-modal')
      })
    },
    showDeleteGradeModal(grade) {
      this.deletableGrade = grade
      this.$nextTick(() => {
        this.confirmationModalShow = true
      })
    },
    async handleDeleteGrade() {
      if (this.deletableGrade.id) {
        try {
          this.isGradeDeleteLoading = true
          await this.deleteGrade(this.deletableGrade.id)
        } finally {
          this.isGradeDeleteLoading = false
          this.deletableGrade = null
          this.confirmationModalHide = !this.confirmationModalHide
          this.fetchGradesList()
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .grades-list {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    min-height: 400px;
  }
  @media (max-width: 769px) {
    .grades-list {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: start;
    }
  }
</style>
