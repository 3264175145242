<template>
  <b-link
    :to="{ name: 'admin-class-details', params: { id: classItem.id } }"
  >
    <b-card
      class="class-item"
    >
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <h5>{{ classItem.name }}</h5>
          <h6>{{ classItem.subjects_count }} subjects</h6>
        </div>
        <h5 class="text-muted">
          {{ classItem.students_amount }} {{ classItem.students_amount == 1 ? camperStudent(1) : camperStudent(2) }}
        </h5>
      </div>
    </b-card>
  </b-link>
</template>

<script>
import {
  BLink,
  BCard,
} from 'bootstrap-vue'

export default {
  name: 'ClassItem',
  components: {
    BLink,
    BCard,
  },
  props: {
    classItem: {
      type: Object,
      default: () => {},
    },
  },
}
</script>

<style lang="scss" scoped>
.class-item {
  box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.12) !important;
  border-radius: 8px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
</style>
