<template>
  <ValidationObserver
    ref="addClassModal"
  >
    <b-modal
      id="add-class-modal"
      ref="add-class-modal"
      title="Add Class"
      centered
      no-close-on-backdrop
      ok-title="Create"
      :ok-disabled="isLoading"
      cancel-variant="outline-secondary"
      size="sm"
      class="p-0"
      @show="setModalData"
      @ok="handleForm"
      @hidden="resetModal"
    >
      <b-overlay
        id="overlay-background"
        :show="isLoading"
        variant="white"
        opacity="1"
        rounded="sm"
      >
        <b-form-group
          label="Class Name"
        >
          <ValidationProvider
            #default="{ errors }"
            name="Name"
            rules="required"
          >
            <b-form-input
              v-model="newClass.name"
              placeholder="Name"
              :state="errors.length > 0 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>

        <b-form-group
          label="Grade"
          class="mb-2"
        >
          <validation-provider
            #default="{ errors }"
            name="grade"
            rules="required"
          >
            <v-select
              v-model="newClass.grade"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="gradesList"
              :clearable="false"
              label="name"
              value="id"
              :reduce="item => item.id"
              placeholder="Select Grade"
              :class="{'is-invalid': errors.length > 0 }"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label="Subjects"
          class="mb-2"
        >
          <v-select
            ref="subjectsSelect"
            v-model="newClass.subjects"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="subjectsList"
            multiple
            label="name"
            value="id"
            placeholder="Select Subjects"
            :reduce="item => item.id"
            @close="createNewSubject = false"
          >
            <template #option="{ name }">
              <span>{{ name }}</span>
            </template>
            <template #list-footer>
              <li
                v-if="!createNewSubject"
                class="mx-1 py-1 border-top border-top-secondary"
              >
                <b-button
                  class="w-100"
                  variant="outline-primary"
                  @click="createNewSubject = true"
                >
                  <span class="ml-25 align-middle">
                    Add New
                    <feather-icon
                      icon="PlusIcon"
                      size="18"
                      class="ml-1"
                    />
                  </span>
                </b-button>
              </li>
              <li
                v-else
                class="mx-1 py-1 border-top border-top-secondary"
              >
                <b-form-group
                  label="Name"
                >
                  <b-form-input
                    ref="footerInput"
                    v-model="newSubjectName"
                    type="text"
                    placeholder="New Subject"
                    @click="handleInputFocus('subjectsSelect')"
                    @keyup.enter="addNewSubject"
                  />
                </b-form-group>
                <div
                  class="w-100 text-primary text-center font-weight-bold cursor-pointer"
                  @click="addNewSubject"
                >
                  <span class="ml-25 align-middle">
                    Apply
                    <feather-icon
                      icon="CheckIcon"
                      size="18"
                      class="ml-1"
                    />
                  </span>
                </div>
              </li>
            </template>
          </v-select>
        </b-form-group>
      </b-overlay>
    </b-modal>
  </ValidationObserver>
</template>

<script>
import {
  BModal,
  BOverlay,
  BFormGroup,
  BFormInput,
  BButton,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import useClassesList from '@/views/admin/academics/classes-list/useClassesList'

export default {
  name: 'AddClassModal',
  components: {
    BModal,
    BOverlay,
    BFormGroup,
    BFormInput,
    BButton,

    vSelect,

    ValidationProvider,
    ValidationObserver,
  },
  props: {
    selectedGradeId: {
      type: String,
      required: true,
    },
    gradesList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      newClass: {
        name: '',
        grade: null,
        subjects: [],
      },
      subjectsList: [],
      createNewSubject: false,
      newSubjectName: '',
      required,
    }
  },
  computed: {
    programId() {
      return this.$store.getters['verticalMenu/getDefaultProgram']
    },
  },
  setup() {
    const {
      fetchSubjects,
      createSubject,
      addClass,
    } = useClassesList()

    return {
      fetchSubjects,
      createSubject,
      addClass,
    }
  },
  created() {
    this.prepareSubjectsList()
  },
  methods: {
    async prepareSubjectsList() {
      const queryParams = {
        program_id: this.programId,
      }
      this.subjectsList = await this.fetchSubjects(queryParams)
    },
    setModalData() {
      this.newClass.grade = this.selectedGradeId
    },
    handleInputFocus(refId) {
      // Focus footerInput manually
      this.$refs.footerInput.focus()
      // Keep the dropdown open
      this.$refs[refId].open = true
    },
    async addNewSubject() {
      // eslint-disable-next-line no-param-reassign
      if (this.newSubjectName) {
        const data = {
          name: this.newSubjectName,
          program_id: this.programId,
        }
        this.isLoading = true
        await this.createSubject(data)
          .then(response => {
            if (response.id) {
              this.subjectsList.push(response)
              this.newClass.subjects.push(response.id)
              this.createNewSubject = false
              this.newSubjectName = ''
            }
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },
    handleForm(bvModalEvt) {
      bvModalEvt.preventDefault()

      return new Promise((resolve, reject) => {
        this.$refs.addClassModal.validate().then(success => {
          if (success) {
            resolve(true)
            this.handleAddClass()
          } else {
            reject()
          }
        })
      })
    },
    async handleAddClass() {
      this.isLoading = true
      const queryParams = {
        program_id: this.programId,
        name: this.newClass.name,
        group_id: this.newClass.grade,
        subjects: this.newClass.subjects,
        type: 'Class',
      }

      await this.addClass(queryParams)
        .then(() => {
          this.$emit('success')
        })
        .finally(() => {
          this.isLoading = false
          this.$bvModal.hide('add-class-modal')
        })
    },
    resetModal() {
      this.newClass = {
        name: '',
        grade: null,
        subjects: [],
      }
    },
  },
}
</script>

<style>

</style>
