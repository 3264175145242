<template>
  <b-card
    class="grade-card"
    header-class="grade-card__header border-bottom"
  >
    <template #header>
      <h5 class="w-100 d-flex align-items-center justify-content-between pb-0 mb-0 handle cursor-move">
        <div class="d-flex flex-column">
          <span>{{ grade.name }} </span>
          <span class="text-muted">{{ grade.students_amount }} {{ grade.students_amount == 1 ? camperStudent(1) : camperStudent(2) }}</span>
        </div>
        <!-- Dropdown -->
        <b-dropdown
          variant="link"
          toggle-class="mx-auto p-0"
          no-caret
          right
        >

          <template #button-content>
            <feather-icon
              icon="MoreHorizontalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>

          <b-dropdown-item
            v-if="$can('update-grade', permissionSubjects.Academics)"
            @click="showEditGradeModal"
          >
            <span>Edit</span>
          </b-dropdown-item>

          <b-dropdown-item
            v-if="$can('delete-grade', permissionSubjects.Academics)"
            @click="showDeleteGradeModal"
          >
            <span>Delete</span>
          </b-dropdown-item>

        </b-dropdown>
      </h5>
    </template>

    <class-item
      v-for="item in grade.classes"
      :key="item.id"
      :class-item="item"
    />

    <b-button
      v-if="$can('create-class', permissionSubjects.Academics)"
      variant="outline-primary"
      class="w-100 mt-1"
      @click="showAddClassModal"
    >
      <span class="mr-25 align-middle">Add class</span>
    </b-button>
  </b-card>
</template>

<script>
import {
  BCard,
  BButton,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import ClassItem from './ClassItem.vue'
import { permissionSubjects } from '@/libs/acl/constants'

export default {
  name: 'GradeCard',
  components: {
    BCard,
    BButton,
    BDropdown,
    BDropdownItem,

    ClassItem,
  },
  props: {
    grade: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      permissionSubjects,
    }
  },
  methods: {
    showAddClassModal() {
      this.$emit('showAddClassToGrade', this.grade)
    },
    showEditGradeModal() {
      this.$emit('showEditGradeModal', this.grade)
    },
    showDeleteGradeModal() {
      this.$emit('showDeleteGradeModal', this.grade)
    },
  },
}
</script>

<style lang="scss" scoped>
.grade-card {
  background: #FBFBFC;
  border: 1px solid #D8D6DE;
  border-radius: 8px;
  width: 350px;
  flex-basis: 350px;
  flex-shrink: 0;
  height: 100%;
  margin: 0 10px 20px;
}

@media (max-width: 769px) {
  .grade-card {
    width: 95%;
    flex-basis: 50px;
  }
}

</style>
